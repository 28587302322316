<template>
  <CFooter>
    <div>
      <a href="https://aederma.es/" target="_blank">{{ COMPANY_DATA.company_name }}</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }}</span
      >
    </div> 
    <div class="m-auto">
        <!-- Enlaces -->
        <a href="./pdfs/Aesthemed_AEDERMA_PoliticaDePrivacidad.pdf" class="px-2" target="_blank">Términos y condiciones</a> |
        <a href="./pdfs/Aesthemed_AEDERMA_AvisoLegal.pdf" class="px-2" target="_blank">Avisos legales</a> |
        <a href="./pdfs/Aesthemed_AEDERMA_PoliticaDeCookies.pdf" class="px-2" target="_blank">Políticas de cookies</a>
    </div>
    <div class="">
    <!-- <div class="ms-auto"> -->
      <!-- <span class="me-1">Desarollado por</span>
      <a href="https://www.trestristestigres.com" target="_blank"><b>tres</b>tristes<b>tigres</b></a> -->
    </div>
  </CFooter>
</template>

<script>
import { COMPANY_DATA } from '@/config';
export default {
  name: 'AppFooter',
  setup() {
    return {
      COMPANY_DATA
    }
  },

}
</script>
